import { MenuEntry, /* DropdownMenuItemType */ } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
   
    ],
  },
  {
    label: t('Earn'),
    icon: 'FarmIcon',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
   
    ],
  },

  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: '/info',
  },

  {
    label: t('Bridge'),
    icon: 'MoonIcon',
    href: 'https://app.chainport.io/?from=BASE&to=ETHEREUM&token=0xb18bfee95e028e399eb90bd457ba892fccda22b8',
    // type: DropdownMenuItemType.EXTERNAL_LINK,
  },
  
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Telegram'),
        href: 'https://t.me/unilayer',
      },
      {
        label: t('Twitter'),
        href: 'https://twitter.com/UniLayer_',
      },
      {
        label: t('Discord'),
        href: 'https://discord.com/invite/BV5y3dd',
      },
      {
        label: t('Medium'),
        href: 'https://unilayer.medium.com',
      },
      // {
      //   label: t('WhitePaper'),
      //   href: 'https://amm.unilayer.app/wp.pdf',
      // },
    ],
  },
]

export default config
